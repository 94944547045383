import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "create-instrctor" }
const _hoisted_2 = { class: "create-instrctor__title" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "form-group" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "form-group" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = {
  key: 1,
  class: "create-instrctor__selected-wrap"
}
const _hoisted_12 = { key: 0 }
const _hoisted_13 = ["for"]
const _hoisted_14 = ["id", "name", "onChange", "checked"]
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { class: "form-group create-instrctor__financial-manager" }
const _hoisted_17 = {
  for: 'isFinancicalManager',
  class: "custom-checkbox"
}
const _hoisted_18 = ["checked"]
const _hoisted_19 = { class: "form-group" }
const _hoisted_20 = { key: 0 }
const _hoisted_21 = { class: "form-group" }
const _hoisted_22 = { key: 0 }
const _hoisted_23 = { class: "form-group" }
const _hoisted_24 = { class: "create-instrctor__button" }
const _hoisted_25 = { key: 0 }
const _hoisted_26 = { key: 1 }
const _hoisted_27 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowBack = _resolveComponent("ArrowBack")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Field = _resolveComponent("Field")!
  const _component_AlertBlock = _resolveComponent("AlertBlock")!
  const _component_Arrow = _resolveComponent("Arrow")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: "/super-admin/school-admin-list",
      class: "create-instrctor__back"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ArrowBack)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      (!this.editTeg)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, " Create school admin "))
        : _createCommentVNode("", true),
      (this.editTeg)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, " Edit school admin "))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_Form, {
      onSubmit: _ctx.createSchoolAdmin,
      class: "create-instrctor__form"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_5, [
          _cache[6] || (_cache[6] = _createElementVNode("label", {
            for: "name",
            class: "form-group-label"
          }, "Name ", -1)),
          _createVNode(_component_Field, {
            class: "form-control",
            type: "text",
            name: "full_name",
            modelValue: this.full_name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.full_name) = $event))
          }, null, 8, ["modelValue"]),
          (this.errorInstructor)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_AlertBlock, {
                  message: this.errorInstructor.full_name
                }, null, 8, ["message"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _cache[7] || (_cache[7] = _createElementVNode("label", {
            for: "email",
            class: "form-group-label"
          }, "Email ", -1)),
          _createVNode(_component_Field, {
            class: "form-control",
            type: "text",
            name: "email",
            modelValue: this.email,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((this.email) = $event))
          }, null, 8, ["modelValue"]),
          (this.errorInstructor)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode(_component_AlertBlock, {
                  message: this.errorInstructor.email
                }, null, 8, ["message"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _cache[8] || (_cache[8] = _createElementVNode("label", {
            for: "choose_school",
            class: "form-group-label"
          }, "Choose school ", -1)),
          _createElementVNode("div", {
            class: "create-instrctor__multi-select-toggle",
            ref: "multiSelectToggleAdmin",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggleActiveClass && _ctx.toggleActiveClass(...args)))
          }, [
            (this.schoolNames.length === 0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_10, "Choose"))
              : (_openBlock(), _createElementBlock("span", _hoisted_11, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.schoolNames, (name, index) => {
                    return (_openBlock(), _createElementBlock("span", { key: name }, [
                      (index > 0)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_12, ", "))
                        : _createCommentVNode("", true),
                      _createElementVNode("span", null, _toDisplayString(name), 1)
                    ]))
                  }), 128))
                ])),
            _createVNode(_component_Arrow, {
              class: _normalizeClass({ rotateArrow: this.isActive })
            }, null, 8, ["class"])
          ], 512),
          _createElementVNode("div", {
            class: _normalizeClass(["create-instrctor__multi-select-wrap", { ActiveMultiSelect: this.isActive }])
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.dataSchools, (item) => {
              return (_openBlock(), _createElementBlock("div", {
                key: item.id,
                class: "create-instrctor__multi-select"
              }, [
                _createElementVNode("label", {
                  for: item.id,
                  class: "custom-checkbox"
                }, [
                  _createElementVNode("input", {
                    id: item.id,
                    name: item.name,
                    onChange: ($event: any) => (_ctx.toggleSchool(item.id, $event, item.name)),
                    checked: _ctx.isChecked(item.id),
                    type: "checkbox"
                  }, null, 40, _hoisted_14),
                  _createElementVNode("span", null, _toDisplayString(item.name), 1)
                ], 8, _hoisted_13)
              ]))
            }), 128))
          ], 2),
          (this.errorSchool)
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                _createVNode(_component_AlertBlock, {
                  message: this.errorSchool.admin_ids
                }, null, 8, ["message"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("label", _hoisted_17, [
            _createElementVNode("input", {
              id: 'isFinancicalManager',
              name: 'FinancicalManager',
              onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.toggleInstructor())),
              checked: this.isFinancicalManager,
              type: "checkbox"
            }, null, 40, _hoisted_18),
            _cache[9] || (_cache[9] = _createElementVNode("span", null, "Financial manager", -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_19, [
          _cache[10] || (_cache[10] = _createElementVNode("label", {
            for: "password",
            class: "form-group-label"
          }, "Password ", -1)),
          _createVNode(_component_Field, {
            class: "form-control",
            type: "password",
            name: "password",
            modelValue: this.password,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((this.password) = $event))
          }, null, 8, ["modelValue"]),
          (this.errorInstructor)
            ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                _createVNode(_component_AlertBlock, {
                  message: this.errorInstructor.password
                }, null, 8, ["message"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_21, [
          _cache[11] || (_cache[11] = _createElementVNode("label", {
            for: "password_confirmation",
            class: "form-group-label"
          }, "Repeat password ", -1)),
          _createVNode(_component_Field, {
            class: "form-control",
            type: "password",
            name: "password_confirmation",
            modelValue: this.password_confirmation,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((this.password_confirmation) = $event))
          }, null, 8, ["modelValue"]),
          (this.errorInstructor)
            ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                _createVNode(_component_AlertBlock, {
                  message: this.errorInstructor.password_confirmation
                }, null, 8, ["message"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_23, [
          _createElementVNode("button", _hoisted_24, [
            (!this.editTeg)
              ? (_openBlock(), _createElementBlock("span", _hoisted_25, "Create"))
              : _createCommentVNode("", true),
            (this.editTeg)
              ? (_openBlock(), _createElementBlock("span", _hoisted_26, "Save"))
              : _createCommentVNode("", true)
          ]),
          (this.serverError)
            ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                _createVNode(_component_AlertBlock, {
                  message: this.serverError
                }, null, 8, ["message"])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["onSubmit"])
  ]))
}